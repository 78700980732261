.statusbox{
    padding:20px;
}
.riderbox{
    padding:10px;
    justify-content: space-between;
    margin-top: 10px;
}
.driverName{
font-size: larger;
}

.delaystyle{

    background-color: #e4cdcd;
    border-color: #b51919;
    color: #b66060;
    
    
    }
.bg1{
    background-color: #1a1919;
    border-radius: 20px;
    border-style: solid;
    border-color: #fbcd28;
    border-width: px;
}
.text-cl{
    color: #e3bb2a;
}
.text-cl-delivered{
    color: #83c80d;
}
.text-see{
    color: #b1b1b1;
}
.bg2{
    background-color: #f2f2f2;
    border-radius: 20px;
    box-shadow: #bab9b9;

    
}
.progress-error{
    color: #83c80d;
}

.main{
    border-radius: 20px;
    
}